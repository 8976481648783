import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html, body {
  background: #181818 !important;
}
`;

/**
 * https://material.io/resources/color/#!/?view.left=0&view.right=0
 */
export const theme = createTheme({});

type Props = {
  children: React.ReactNode;
};

export const StyleProvider = (props: Props) => {
  const { children } = props;

  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
};
